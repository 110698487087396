import React from 'react';

import { HelpArticle, SubTitle } from 'components/help/helpArticle';
import Code from 'components/help/codeSnippet';
import Image from 'components/help/helpImage';
import Link from 'components/ui/link';

import screen1 from 'img/help/helpscout/helpscout1.png';
import screen2 from 'img/help/helpscout/helpscout2.png';

import logo from 'img/help/integrations/helpscout.png';

const Content = () => {
  return (
    <div>
      <SubTitle>What is Help Scout?</SubTitle>
      <p>
        <strong>
          Help Scout is a handy support tool that lets you communicate with your users via emails
        </strong>
        . This software is useful when you want to collaborate with your team on customer service.
        It lets you share mailbox with teammates, so you can work on issues together. What’s more,
        you can use Help Scout to add email widget to your website, so people can use it to send
        tickets to your mailbox. It could be nice alternative to contact forms.
      </p>
      <Image
        src={screen1}
        alt="Help Scout integration"
        title="Your user can submit ticket using this widget."
      />
      <SubTitle className="mt-5">How does this integration work?</SubTitle>
      <p>
        This integration makes solving technical issues easier for you. Why? Because{' '}
        <strong>it will send session replay links along with tickets</strong>, every time someone
        will send it to you using Help Scout widget. Then you can easily check what people did
        before submitting an issue and what exactly was wrong, so you can resolve it faster. The
        result looks like below:
      </p>
      <Image
        src={screen2}
        lazy
        alt="Help Scout integration"
        title="Click link to see what user did before sending this message"
      />
      <p>
        It could be especially useful if you use user identification feature in LiveSession. You
        could then check entire history of session replays for specific customer. To make this work
        check{' '}
        <Link href="/help/user-identification-custom-properties/" target="_blank">
          this article
        </Link>
        .
      </p>
      <p>
        <strong>Do you want to minimize number of follow-ups sent to your customers?</strong> We
        guess you want, so let’s try it and make your job easier!
      </p>
      <SubTitle className="mt-5">How can I install this integration?</SubTitle>
      <span>
        To connect LiveSession with your Help Scout Beacon, you need to add the code below before
        your{' '}
      </span>{' '}
      <Code inline>{'</body>'}</Code> <span> closing tag:</span>
      <Code margin="1rem 0" copy>
        {`<script>
if(__ls && Beacon) {
  __ls('getSessionURL', function(url, isNew) {
      if(isNew) {
        Beacon("identify", {
        session_replay: url
      });   
    }
  })
}
</script>`}
      </Code>
    </div>
  );
};

const related = [];

export const frontmatter = {
  metaTitle: 'LiveSession and Help Scout integration',
  metaDescription: 'Send LiveSession recordings with tickets to Help Scout',
  canonical: '/help/help-scout-integration/',
  logo,
};

const Wrapped = HelpArticle(Content);

export default () => (
  <Wrapped
    related={related}
    section="Integrations"
    title={frontmatter.metaTitle}
    {...frontmatter}
  />
);
